import axios from 'axios';
import 'font-awesome/css/font-awesome.min.css';
import React, { Component } from 'react';
import { AcceptHosted } from 'react-authorize-net';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import big_logo from '../../Assets/Images/Fire_Recovery_USA_Logo_250x55-1.gif';
import * as constants from '../Global/Global';
import Layout from '../Layout/Layout';
import LoadingSpinner from './Spinner';
toast.configure();
class PaymentForm extends Component{
    constructor(props) {
      super(props);
      this.state = {
          claimDetails :{
          claim_id: '',
          invoiceNumber: '',
          amount: '',
          payment_plan: '',
          validForm:false,
          invoiceValid: false,
          amountValid: false,
          alert:'' ,
          status:'',
          departmentName:'',
          creditCardFee:0,
          echeckFee:0
      },
      formToken: 0

    };
  }
  /*
  This will life cycle method  handle the data fetch from DB with formToken
  */
  componentDidMount() {
    //console.log(process.env.PUBLIC_URL);
    const invoice = this.props.match.params.id;
    const type = this.props.match.params.type;
    const invoice_type = this.props.match.params.invoice_type;

    const axiosConfig = { params: {a: 0, fl:1, payment_plan: this.state.payment_plan}};
    if ( typeof this.props?.location?.state?.payment_amount != 'undefined' ) {
      axiosConfig.params.a = Number(this.props?.location?.state?.payment_amount);
      axiosConfig.params.payment_plan = this.props?.location?.state?.payment_plan;
    }

    axios.get(constants.PAYMENTMETHODS+invoice_type+'/'+invoice+'/'+type, axiosConfig)
    .then((response)=> {
    const res =response.data.result;
    this.state.payment_plan = this.props?.location?.state?.payment_plan;
    if(invoice_type !== 'inspection') //For claim payments
    { 
                  if( res.length === 0)
                  {
                    alert( 'No claim found with this invoice number');
                  }
                  else if(res.claim.status =='Payment Received')
                  {
                   
                  toast.error('The specified invoice is already paid.'); 
                  this.props.history.push('/'); 

                  }
                  else
                  {
                      const invoiceData = res.claim ;
                      const currentClaimDetails = {...this.state.claimDetails};
                      currentClaimDetails.departmentName = invoiceData.name;
                      currentClaimDetails.creditCardFee =0;
                      currentClaimDetails.echeckFee = 0;
                      currentClaimDetails.amount = (1 * invoiceData.amount_invoiced).toFixed(2);
                      currentClaimDetails.payment_plan = this.state.payment_plan;
                      currentClaimDetails.amountValid =true;
                      currentClaimDetails.alert = '';
                      currentClaimDetails.status = invoiceData.status;
                      currentClaimDetails.invoiceNumber = invoiceData.invoice_number;
                      currentClaimDetails.invoiceValid = true;
                      currentClaimDetails.validForm = true;
                      currentClaimDetails.claim_id = invoiceData.original_run_id;
                      this.setState({claimDetails:currentClaimDetails});
                      const formToken = res.form_token ;
                      this.setState({formToken:formToken});
                      console.log(this.state.payment_plan);
                      var submit_button = document.getElementById('submit_button');
                      submit_button.click();
                      //console.log(this.state.formToken);
                   }
    }else
    {

      console.log(res);
      if( !res)
              {                
                toast.error('No Inspection/Claim invoice found for this invoice number.');  
              }
              else if(res.claim.status =='Paid')
              {                
                 toast.error('The specified invoice is already paid.'); 
                 this.props.history.push('/'); 

              }
              else
              { 
                 const invoiceData = res.claim ;
                 console.log(invoiceData.department.name);
                 const currentClaimDetails = {...this.state.claimDetails};
                  currentClaimDetails.departmentName = invoiceData.department.name;


                  if(type === 'credit_card')
                    currentClaimDetails.amount = ((1*invoiceData.invoice_total)+(1*invoiceData.card_fee)).toFixed(2);
                  else
                    currentClaimDetails.amount = ((1*invoiceData.invoice_total)+(1*invoiceData.echeck_fee)).toFixed(2);


                
                  //currentClaimDetails.amount = invoiceData.invoice_total.toFixed(2);
                  currentClaimDetails.invoice_subtotal = ( 1 * invoiceData.invoice_subtotal ).toFixed(2);

                  
                  currentClaimDetails.credit_surcharge = invoiceData.department.credit_surcharge;
                  currentClaimDetails.echeck_surcharge = invoiceData.department.echeck_surcharge;
                  currentClaimDetails.check_surcharge = invoiceData.department.check_surcharge;


                  currentClaimDetails.card_fee = invoiceData.card_fee;
                  currentClaimDetails.echeck_fee = invoiceData.echeck_fee;
                  currentClaimDetails.check_fee = invoiceData.check_fee;



                  currentClaimDetails.late_fee = invoiceData.late_fee;
                  currentClaimDetails.invoice_type ='inspection';
                  
                  
                  
                  currentClaimDetails.amountValid =true;
                  currentClaimDetails.alert = '';
                  currentClaimDetails.status = invoiceData.status;
                  currentClaimDetails.invoiceNumber = invoice;
                  currentClaimDetails.invoiceValid = true;
                  currentClaimDetails.validForm = true;
                  currentClaimDetails.claim_id = invoiceData.original_run_id;
                  this.setState({claimDetails:currentClaimDetails});
                  const formToken = res.form_token ;
                  this.setState({formToken:formToken});
                  var submit_button = document.getElementById('submit_button');
                  submit_button.click();

              }


    }
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  /*
  This will handle the JSX Rendering
  */
  render ()
  {
      return (
        <Layout>
        <div className="custom-container">
              <div className="payment-wrap">
                  <div className="logo"><img src={big_logo} alt=""/></div>
                  <div className="pay-method">
                      <h1>{this.state.claimDetails.departmentName}</h1>
                      {! this.state.claimDetails.validForm ? <LoadingSpinner />:""}
                      <h3>Invoice Amount : $ {this.state.claimDetails.amount}</h3>
                      <div className="redirect"><h4>Redirecting to Authorize.net form....</h4><span className="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span></div>
                  </div>
              </div>
              <div className="columns d-none">
                <ul className="price">
                  <li className="grey">
                   <AcceptHosted className='payment_form' mode='production' formToken={this.state.formToken} type="redirect">
                   <button id="submit_button" onClick={this.submitForm}>
                   Pay by Credit Card Online <i>→</i>

                   </button>
                   </AcceptHosted >
                  </li>
                </ul>
              </div>
          </div>
          </Layout>

      );
  };
}
export default PaymentForm;
