import React,{Component} from 'react';
import big_logo from '../../Assets/Images/Fire_Recovery_USA_Logo_250x55-1.gif';
import 'font-awesome/css/font-awesome.min.css';
import  LoadingSpinner from './Spinner';
import axios from 'axios';
import { Link } from 'react-router-dom';
import * as constants from '../Global/Global';
import '../../Assets/Css/PrintReceipt.css';
import '../../Assets/Css/bootstrap.css';


import Pdf from "react-to-pdf";

const ref = React.createRef();


class PrintReceipt extends Component{
  constructor(props) {
    super(props);
    this.state = {
        claimDetails :{
        claim_id: '',
        amount: '',
        alert:'' ,
        departmentName:'',
        status:'',
        displayPayable:'',
        paymentType:'',
        incidentLocation: '',
        checkDate:'',
        remittanceAddress:''
    }
  };
}

  /*
  This will life cycle method  handle the data fetch from DB
  */
  componentDidMount() {

    const invoice = this.props.match.params.id;
    axios.get(constants.CLAIMFULLPAYMENTDETAILS+invoice)
    .then((response)=> {
    const res =response.data.result;
    //console.log(res[0].amount_invoiced);
    if( res.length === 0)
    {
      alert( 'No claim found with this invoice number');
    }
    else if(res.claim == false)  {
      this.props.history.push('../');

    }
    else
    {
      if(res.claim == false)
        this.props.history.push('../');
      const invoiceData = res.claim ;
      const currentClaimDetails = {...this.state.claimDetails};
      currentClaimDetails.amount = ( 1 * invoiceData.amount_invoiced ).toFixed(2);;
      currentClaimDetails.departmentName = invoiceData.name;
      currentClaimDetails.invoiceValid = true;
      currentClaimDetails.status = invoiceData.status;
      currentClaimDetails.paymentType = invoiceData.payment_name;
      currentClaimDetails.incidentLocation = invoiceData.incident_location;
      currentClaimDetails.checkDate = invoiceData.check_date;
      currentClaimDetails.remittanceAddress = invoiceData.RemittanceAddress;






      currentClaimDetails.displayPayable = invoiceData.display_payable;
      this.setState({claimDetails:currentClaimDetails});

        /*if(invoiceData.status !== 'Payment Received' )
        {
          this.props.history.push('../');
        }
        else*/ if(currentClaimDetails.paymentType == '' )
        {
          this.props.history.push('../');
        }
        else
          {
               window.print();
          }

     }
    })
    .catch(function (error) {
      console.log(error);
    });
  };

  render ()
  {
      return (


<React.Fragment>
        <div className="custom-container">
          <div className="payment-wrap">
            <div className="print-wrap">
              <div className="receipt-header">
                <div className="address">
                 <p className='text-area-content'>
                 {this.state.claimDetails.departmentName}<br/>

                 c/o Fire Recovery USA<br/>
                 2271 Lava Ridge Ct Suite 120<br/>
                 Roseville, CA 95661-3065<br/>

           

                 </p>
                </div>
                {/*  <div className="address">
                  FIRST BAPTIST CHURCH <br />
                  301 W FERGUSON ST <br />
                  TYLER, TX 75702-5630
                </div> */}
                <div className="logo"><img src="images/Tyler_Texas.jpg" alt="" /></div>
                <div className="paid">Paid</div>
              </div>
              <h2>RECEIPT</h2>
              <div className="table-responsive">
                <table>
                  <tbody><tr>
                      <td style={{fontWeight: 900}}>Invoice #</td>
                      <td>{this.props.match.params.id}</td>
                    </tr>
                    {/*<tr>
                      <td style={{fontWeight: 900}}>Re:</td>
                      <td>
                        <p className='text-area-content'>{this.state.claimDetails.remittanceAddress ? this.state.claimDetails.remittanceAddress: '-'}</p>
                      </td>
                    </tr>*/}
                    <tr>
                      <td style={{fontWeight: 900}}>Date Paid</td>
                      <td>{this.state.claimDetails.checkDate}</td>
                    </tr>
                    <tr>
                      <td style={{fontWeight: 900}}>Payment Method</td>
                      <td>Paid ${this.state.claimDetails.amount}  via {this.state.claimDetails.paymentType}</td>
                    </tr>
                  </tbody></table>
              </div>
              <div className="table-responsive">
                <table className="pricing-table">
                  <tbody><tr>
                      <th>Type</th>
                      <th>Detail</th>
                      <th>Total</th>
                    </tr>
                    <tr>
                      <td>Claim</td>
                      <td>{this.state.claimDetails.incidentLocation} <br />
                         </td>
                      <td>${this.state.claimDetails.amount}</td>
                    </tr>

                    <tr>
                      <td>&nbsp;</td>
                      <td>&nbsp;</td>
                      <td style={{fontWeight: 900}}>Total:	${this.state.claimDetails.amount}</td>
                    </tr>
                  </tbody></table>
              </div>
            </div>
          </div>
        </div>

</React.Fragment>
      );
  };
}
export default PrintReceipt;
