

import React,{Component} from 'react';
import big_logo from '../../Assets/Images/Fire_Recovery_USA_Logo_250x55-1.gif';
import slide1 from '../../Assets/Images/where-1.png';
import slide2 from '../../Assets/Images/where-2.png'; 
import axios from 'axios';
import Layout from '../Layout/Layout';
import * as constants from '../Global/Global';
import Popup from './Popup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import ReCAPTCHA from "react-google-recaptcha";
toast.configure();
 
const recaptchaRef = React.createRef();
class Home extends Component{
  constructor(props) {
    super(props);
    this.state = {
    invoice: '',
    amount: 0,
    validForm:false,
    invoiceValid: false,
    amountValid: false,
    showInvoiceHelp:false,
    showInspectionInvoiceHelp:false,
    alert:'',
    status:'',
    redirect:false,
    showHide:false,
    inspectionInvoiceDetails:{}
  };
}
/*
This will handle the submit Button Click
*/
handleOk = (event)=>{

  const recaptchaValue = recaptchaRef.current.getValue();
  if(!recaptchaValue)
  {
    toast.error('Please check the the captcha form.');
    return false;
  }
  event.preventDefault();
  let invoice = this.state.invoice;
  invoice = invoice.trim();
  let amount = this.state.amount;

  amount = encodeURI(amount);

  let amount_invoiced = 0;
  this.setState({validForm: false});
  //Fetch the claim details
  axios.get(constants.PAYCLAIM+invoice+'/'+amount+'/')
  .then((response)=> {
const res =response.data.result;
const invoice_type =response.data.invoice_type;
if(invoice_type == 1)
{
          if( res.length !== 0)
              amount_invoiced = Number(res[0].amount_invoiced);

            if( res.length === 0)
            {
               
               toast.error('No claims found for this invoice number and amount.');
               recaptchaRef.current.reset(); 
            }
            else if(res[0].status =='Payment Received')
            {
             recaptchaRef.current.reset();   
             toast.error('The specified invoice is already paid.');  

            }
            else if (res[0].status === 'Ready for Collections') {
                alert('We can not accept payment for claims with the ‘Ready for Collection’ status.');
                recaptchaRef.current.reset(); 
            }
             else if( res[0].amount_invoiced ===0)
            {
                   
                  toast.error('The amount invoiced for this claim is zero.');
                  recaptchaRef.current.reset(); 
            }
            else if( amount_invoiced !==  this.state.amount)
            {
              console.log( amount_invoiced);
              console.log( this.state.amount);
              recaptchaRef.current.reset(); 

                    //alert('The actual amount is Full amount'+res[0].amount_invoiced);
                  this.setState({alert: 'The actual amount invoiced for this claim is '+res[0].amount_invoiced});
                  //console.log();
                  //alert( );
                   toast.error('We do not accept partial payments. Please enter the full amount shown on your invoice.'); 
                  this.setState({redirect: false  });
            }
            else {
              this.setState({redirect: true});
              if (this.state.redirect) {
                    //return <Redirect to={`./paymentmethods/${this.state.invoice}`} />
                    this.props.history.push('./paymentmethods/claim/'+this.state.invoice);
                  }
            }
  }
  else
  {
    console.log(res);
    if(!res)
      {
         recaptchaRef.current.reset(); 
         toast.error('No Inspection/Claim invoice found for this invoice number and amount.');  
      }
      else if(res.status=='Paid')
      {
      recaptchaRef.current.reset();   
      toast.error('The specified invoice is already paid.');  

      }
      else
      {
        this.setState({inspectionInvoiceDetails: {...res}}); //Set the ajax request to state variable
        this.handleModalShowHide();///Show invoice popup
        toast.success('Please confirm the selected Inspection Invoice.');  
        recaptchaRef.current.reset(); 
      } 
  }          

  this.setState({validForm: true});
  })
  .catch(function (error) {
    console.log(error);
     toast.error(error.message);  
  });
}
  handleVerify = ()=> { 
        this.setState({ showHide: false });
        this.props.history.push('./paymentmethods/inspection/'+this.state.inspectionInvoiceDetails.id); 
    }

   handleModalShowHide = ()=> { 
        this.setState({ showHide: !this.state.showHide }) 
    }
  /*
  This will handle the help button click
  */
  handleInvoiceHelp = (event)=>{
       let showInvoiceHelp = this.state.showInvoiceHelp;
       this.setState({showInvoiceHelp: !showInvoiceHelp});
  }
  /*
  This will handle the form field validation
  */
  /*
  This will handle the help button click
  */
  handleInspectionInvoiceHelp = (event)=>{
       let showInspectionInvoiceHelp = this.state.showInspectionInvoiceHelp;
       this.setState({showInspectionInvoiceHelp: !showInspectionInvoiceHelp});
  }
  /*
  This will handle the form field validation
  */
  validateField(fieldName, value) {

    let invoiceValid = this.state.invoiceValid;
    let amountValid = this.state.amountValid;

    switch(fieldName) {
      case 'invoice':
        invoiceValid = (value==='') ? false : true;
        break;
      case 'amount':
        amountValid = (value==='') ? false : true;
        break;
      default:
        break;
    }
    this.setState({invoiceValid: invoiceValid, amountValid: amountValid}, this.validateForm);
  }
  /*
  This will handle the callback from  form field validation
  */
  validateForm = () =>
  {
    this.setState({validForm: this.state.amountValid && this.state.invoiceValid});
    //this.setState({validForm:this.state.invoiceValid});  // For inspection only the invocie number is required
  }
  /*
  This will handle the form inputs change event
  */
  handleUserInput (e) {
    const name = e.target.name;
    const value = e.target.value;

    this.setState({[name]:  e.target.name === 'amount' ? e.target.valueAsNumber : value},
                  () => { this.validateField(name, value) });
  }
  onChange = (value) => {
  console.log("Captcha value:", value);
}
  /*
  This will handle the JSX Rendering
  */
  render ()
  {
        return (
          <Layout>
            <div className="custom-container">
            <div className="payment-wrap">
            <Popup handleVerify={this.handleVerify} inspectionInvoiceDetails={this.state.inspectionInvoiceDetails} handleModalShowHide={this.handleModalShowHide} showHide={this.state.showHide} />
                        <form  onSubmit={e => {           e.preventDefault();         }}    action="/">
                          <div className="logo"><img src={big_logo} alt="" /></div>
                          <h3>Make a Payment</h3>
                          <div className="invoice-field clearfix">
                            <div className="lable-sec">
                              <h4>Please Enter your Invoice number</h4>
                              
                            </div>
                            <div className="input-sec">
                              <input className="form-control" onChange={(event) => this.handleUserInput(event)} name="invoice" type="text" />
                            </div>
                          </div>
                           


                            <div className="invoice-field clearfix">
                            <div className="lable-sec">
                               
                              {this.state.showInvoiceHelp? '-' : '+'}<a href="#" id="showfield" onClick={this.handleInvoiceHelp}>Help me find my claim invoice number</a>
                              <div   className={!this.state.showInvoiceHelp? 'd-none invoicetoggle' : 'invoicetoggle'}   >
                              <img src = {slide1}/> 
                            </div> 
                              <br/>{this.state.showInspectionInvoiceHelp? '-' : '+'}<a href="#" id="showfield" onClick={this.handleInspectionInvoiceHelp}> Help me find my inspection invoice number</a>
                           
                              <div   className={!this.state.showInspectionInvoiceHelp? 'd-none invoicetoggle' : 'invoicetoggle'}   >
                            <img src = {slide2}/> 
                          </div>
                            </div>
                             
                          </div>
                          
                          


                          <div className="amount-field">
                          <div className="amount-form"><label>Amount</label><input className="form-control"  onChange={(event) => this.handleUserInput(event)} name="amount" type="number" /></div>
                          <div className="invoice-field clearfix">
                          <div className="input-sec" style={{marginBottom: "15px"}}>

                          <ReCAPTCHA    ref={recaptchaRef} sitekey={constants.RECAPCHAKEY}    onChange={this.onChange}/>  
                            
                          </div> 
                          </div> 
                            <button type="button" className="btn-submit"  disabled={!this.state.validForm}   onClick={this.handleOk}>SUBMIT</button>
                          </div>
                          
                          </form>
            </div>
          </div>
        </Layout>
        );

  };
}
export default Home;
