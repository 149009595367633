


const prod={
   RH_URL : "https://therecoveryhub.com/",
   INSP_URL : "https://theinspectionhub.com/",
   RECAPCHAKEY : `6Lcx_NkaAAAAAEcPorAuyoIeuAFJENWW98k3VR3M` 
}

const dev={
   RH_URL:"http://zdev.therecoveryhub.com.local/",
   INSP_URL:"http://zdev.theinspectionhub.com.local/",    
   RECAPCHAKEY : `6Le6njMiAAAAAIT86cWiTkWIlgM-XZ70Lg-Yrp8A`
}

const stage={
   RH_URL:"https://stag.therecoveryhub.com/",
   INSP_URL:"https://stag.theinspectionhub.com/",    
   RECAPCHAKEY : `6Le6njMiAAAAAIT86cWiTkWIlgM-XZ70Lg-Yrp8A`     
}

const beta={
   RH_URL:"https://beta.therecoveryhub.com/",
   INSP_URL:"https://beta.theinspectionhub.com/",
   RECAPCHAKEY : `6Le6njMiAAAAAIT86cWiTkWIlgM-XZ70Lg-Yrp8A`
}

let Config=dev;
if ( process.env.REACT_APP_ENV=='development' ) Config=dev;
if ( process.env.REACT_APP_ENV=='staging' ) Config=stage;
if ( process.env.REACT_APP_ENV=='production' ) Config=prod;
if ( process.env.REACT_APP_ENV=='beta' ) Config=beta;
export default Config;